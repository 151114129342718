import React from "react";
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";
import "./App.css";
import "animate.css";

import Notification from "./Screens/Notification/Notification";
import { NotificationProvider } from "./Screens/Notification/NotificationContext";

// Import your components for different routes
import "primeicons/primeicons.css";
import { PrimeReactProvider } from "primereact/api";
import "primereact/resources/themes/viva-light/theme.css";
import { io } from "socket.io-client";
import Brokerage from "./Screens/Brokerage/Brokerage";
import Brokers from "./Screens/Brokers/Brokers";
import Holding from "./Screens/Holding/Holding";
import Home from "./Screens/Home/Home";
import Login from "./Screens/Login/Login";
import LoginProvider from "./Screens/Login/LoginContext";
import Pending from "./Screens/Pending/Pending";
import Position from "./Screens/Position/Position";
import MySidebar from "./Screens/Sidebar/MySidebar";
import { SidebarProvider } from "./Screens/Sidebar/SidebarContext";
import Transaction from "./Screens/Transaction/Transaction";
import Users from "./Screens/Users/Users";
import Watchlist from "./Screens/Watchlist/Watchlist";
import SocketContect from "./SocketContext/SocketContext";
import Deposit from "./Screens/Deposit/Deposit";
import Withdraw from "./Screens/Withdraw/Withdraw";
import Profile from "./Screens/Profile/Profile";
import UsersNew from "./Screens/Users/Newui/UsersNew";

var socket = io("https://emit.dfcapitals.com");

const menuItems = [
  { name: "Home", component: Home },
  { name: "Users", component: UsersNew },
  { name: "Deposit", component: Deposit },
  { name: "Withdraw", component: Withdraw },
  { name: "Positions", component: Position },
  { name: "Transactions", component: Transaction },
  { name: "Pendings", component: Pending },
  { name: "Brokers", component: Brokers },
  { name: "Watchlist", component: Watchlist },
  { name: "Brokerage", component: Brokerage },
  { name: "Holdings", component: Holding },
  { name: "Profile", component: Profile },
  // Add more routes for logged-in users as needed
];

function App() {
  // React.useEffect(() => {
  //   // Check if the user is on a mobile device
  //   const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  //   console.log(isMobile);
  //   // Set initial scale based on device type
  //   const initialScale = isMobile ? "0.2" : "1.0";
  //   const initialWidth = isMobile ? "800" : "device-width";

  //   // Dynamically update the viewport meta tag
  //   const viewportMetaTag = document.querySelector('meta[name="viewport"]');
  //   if (viewportMetaTag) {
  //     viewportMetaTag.setAttribute(
  //       "content",
  //       `width=${initialWidth},initial-scale=${initialScale}, maximum-scale=1.0,minimum-scale=0.2, user-scalable=yes`
  //     );
  //   }
  // }, []);
  socket.on("connect", () => {
    if (localStorage.getItem("isLoggedMaster") == "1") {
      var get_data = JSON.parse(localStorage.getItem("mastersDetails"));

      socket.emit("login", {
        type: "Master",
        id: localStorage.getItem("mastersId"),
        super_id: get_data.super_id,
        server: get_data.admin_id,
      });
    }
  });

  // localStorage.clear();
  const isLogged = localStorage.getItem("isLoggedMaster") === "1";

  return (
    <div className="App">
      <SocketContect.Provider value={socket}>
        <SidebarProvider>
          <NotificationProvider>
            <LoginProvider>
              <PrimeReactProvider>
                <Router>
                  {isLogged && <MySidebar />}

                  <Routes>
                    {isLogged ? (
                      menuItems.map((menuItem) => (
                        <Route
                          key={menuItem.name}
                          path={`/${menuItem.name.toLowerCase()}`}
                          element={<menuItem.component />}
                        />
                      ))
                    ) : (
                      <Route path="login" element={<Login />} />
                    )}
                    <Route
                      path="*"
                      element={<Navigate to={isLogged ? "/Home" : "/login"} />}
                    />
                  </Routes>
                </Router>
                <Notification />
              </PrimeReactProvider>
            </LoginProvider>
          </NotificationProvider>
        </SidebarProvider>
      </SocketContect.Provider>
    </div>
  );
}

export default App;
