import React from "react";
import styled, { css } from "styled-components";
import { NewTheme } from "../../Theme/Theme";
import Php from "../../../Backend/Php";
import { useNotification } from "../../Notification/NotificationContext";
import { Card } from "primereact/card";

const php = new Php();

const RightPanelContainer = styled.div`
  flex: 3;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  border-left: 0px solid black; /* Add a 1px black border on the left side */
`;

export const BalanceWrapper = styled.div`
  /* border-bottom: 1px solid lightgray; */
  /* margin-bottom: 20px; */
  display: flex; /* Make child elements horizontal */
  flex-direction: column;
  width: 90%;
  justify-content: space-between; /* Add space between children */
`;

export const BalanceContainer = styled.div`
  margin-bottom: 20px;
  display: flex; /* Make child elements horizontal */
  align-items: center; /* Vertically center align child elements */
  justify-content: space-between; /* Add space between children */
`;

export const BalanceInput = styled.input`
  background-color: white;
  color: rgb(0, 0, 0);
  transition: all 0.2s ease 0s;
  height: 40px;
  border-radius: 5px;
  width: 50%;
  border: 0.5px solid lightgray;
  padding-left: 10px;
`;

export const BalanceUpdateButton = styled.button`
  display: flex; /* Center content horizontally and vertically */
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 150px;
  border-width: 1px;
  border-style: solid;
  border-radius: 5px;
  color: white;
  padding: 14px 28px;
  font-weight: bold;
  font-size: 14px;
  cursor: pointer;
  margin: 0px;
  background-color: ${NewTheme.MainColor};
  align-self: center;
`;

export const BalanceLabel = styled.div`
  font-weight: bold;
  margin-bottom: 5px;
  padding-left: 5px;
  padding-right: 10px;
  color: gray;
  text-align: left;
`;

export const BalanceAmountText = styled.div`
  font-weight: bold;
  margin-bottom: 5px;
  padding-left: 20px;
  color: gray;
`;
export const CreateClientButton = styled.button`
  padding: 8px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  transition: background 0.3s ease;
  width: ${(props) => (props.width ? props.width : null)};

  ${(props) =>
    props.active
      ? css`
          border: 1px solid ${props.color ? props.color : "#3959ab"};
          background-color: ${props.color ? props.color : "#3959ab"};
          color: #fff;
        `
      : css`
          background: none;
          color: ${props.color ? props.color : "#3959ab"};
          border: 1px solid ${props.color ? props.color : "#3959ab"};
        `}
`;

export default function AddBroker(props) {
  const [loading, setLoading] = React.useState(false);
  const { addNotification } = useNotification();

  const [name, setName] = React.useState("");
  const [username, setUserame] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [referral, setReferral] = React.useState("");

  React.useEffect(() => {}, []);

  const add_broker = (e) => {
    if (name == "") {
      addNotification("Please Enter Name", "error");
    } else if (username == "" || username.length > 14 || username.length < 2) {
      addNotification("Please Enter Username ", "error");
    } else if (password.length < 3) {
      addNotification(
        "Please Enter Password with atleast 3 character",
        "error"
      );
    } else {
      if (!loading) {
        setLoading(false);
        let data = {
          sr: localStorage.getItem("server"),
          jwt: localStorage.getItem("token"),
          m_id: localStorage.getItem("mastersId"),
          name: name,
          username: username,
          password: password,
        };

        php.add_broker(data).then((r) => {
          setLoading(false);
          if (r.error === "False") {
            addNotification(r.message, "success");
            props.reload();
            setName("");
            setUserame("");
            setPassword("");
          } else {
            addNotification(r.message, "error");
          }
        });
      }
    }
  };

  return (
    <div
      style={{
        width: "100%",
        background: "white",
        height: "100%",
        // padding: 10,
        boxSizing: "border-box",
      }}
    >
      {/* <Patti /> */}
      <Card title="Add Broker" style={{ margin: 10, position: "relative" }}>
        <CreateClientButton
          active={false}
          color={NewTheme.redcolor}
          onClick={() => props.close()}
          style={{ position: "absolute", top: 10, right: 5 }}
        >
          Close
        </CreateClientButton>
        <RightPanelContainer>
          <BalanceWrapper>
            <BalanceContainer>
              <BalanceLabel> Name </BalanceLabel>
              <BalanceInput
                type="text"
                onChange={(e) => setName(e.target.value)}
                value={name}
                placeholder="Enter Name "
              />
            </BalanceContainer>
            <BalanceContainer>
              <BalanceLabel>Username </BalanceLabel>
              <BalanceInput
                type="text"
                placeholder="Enter Useranme "
                onChange={(e) => setUserame(e.target.value)}
                value={username}
              />
            </BalanceContainer>
            <BalanceContainer>
              <BalanceLabel>Password</BalanceLabel>
              <BalanceInput
                type="text"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Enter Password"
              />
            </BalanceContainer>
          </BalanceWrapper>
          <BalanceUpdateButton onClick={add_broker}>Save</BalanceUpdateButton>
        </RightPanelContainer>
      </Card>
    </div>
  );
}
