// SoundUtility.js
import { Howl } from "howler";
// import pendingaudio from "pending.mp3";
// import surveyaudio from "survey.mp3";
// import fsqaudio from "fsqoff.mp3";
// import booundryaudio from "boundry.mp3";
const pendingaudio = `${process.env.PUBLIC_URL}/pending.mp3`;
const surveyaudio = `${process.env.PUBLIC_URL}/survey.mp3`;
const fsqaudio = `${process.env.PUBLIC_URL}/fsqoff.mp3`;
const booundryaudio = `${process.env.PUBLIC_URL}/boundry.mp3`;

// import successAudio from "./noti.mp3";

export const pendningplaySound = () => {
  var pendingsound = new Howl({
    src: [pendingaudio],
    volume: 1.0,
  });
  pendingsound.play();
};

export const surveyPlaysound = () => {
  var surveysound = new Howl({
    src: [surveyaudio],
    volume: 1.0,
  });
  surveysound.play();
};

export const boundryPlaysound = () => {
  var boundsound = new Howl({
    src: [booundryaudio],
    volume: 1.0,
  });
  boundsound.play();
};

export const fsqoffPlaysounnd = () => {
  var fsqoffsound = new Howl({
    src: [fsqaudio],
    volume: 1.0,
  });

  fsqoffsound.play();
};
