import React, { useState } from "react";
import { Menubar } from "primereact/menubar";
import LoginLogsNew from "./TABS/LoginLogsNew";
import NewBasic from "./TABS/NewBasic";
import EditClient from "./EditClient";
import ScriptClient from "./TABS/ScriptClient";
import LedgerLogs from "../Ledger/LedgerLogs";
import RejectionLogs from "../RejectionLogs/RejectionLogs";
import LoginLogs from "../LoginLogs/LoginLogs";
import Fatak from "../Fatak/Fatak";
import Locks from "../Lock/Locks";

export default function NewOverView({ selectedClient, reload }) {
  const [menuselection, setMenu] = useState(0);

  const items = [
    {
      label: "Basic",
      icon: "pi pi-home",
      command: () => {
        setMenu(0);
      },
    },
    {
      label: "Edit",
      icon: "pi pi-star",
      command: () => {
        setMenu(1);
      },
    },

    {
      label: "Scripts",
      icon: "pi pi-envelope",
      command: () => {
        setMenu(2);
      },
    },
    {
      label: "Reports",
      icon: "pi pi-search",
      items: [
        {
          label: "Ledger",
          icon: "pi pi-bolt",
          command: () => {
            setMenu(3);
          },
        },
        {
          label: "Rejection Logs",
          icon: "pi pi-server",
          command: () => {
            setMenu(4);
          },
        },
        {
          label: "Login Logs",
          icon: "pi pi-pencil",
          command: () => {
            setMenu(5);
          },
        },
        {
          label: "Boundry",
          icon: "pi pi-pencil",
          command: () => {
            setMenu(6);
          },
        },
        {
          label: "Locks",
          icon: "pi pi-pencil",
          command: () => {
            setMenu(7);
          },
        },
      ],
    },
  ];

  return (
    <div style={{ flex: 1, display: "flex" }}>
      <div style={{ flex: 3, height: "90%", padding: 10 }}>
        <Menubar
          style={{ borderRadius: 0, marginBottom: 5, height: 50 }}
          model={items}
        />

        {menuselection == 0 && (
          <NewBasic
            key={selectedClient.id}
            reload={() => reload()}
            clientData={selectedClient}
          />
        )}
        {menuselection == 1 && (
          <EditClient
            key={selectedClient.id}
            reload={() => reload()}
            clientData={selectedClient}
          />
        )}
        {menuselection == 2 && (
          <ScriptClient
            key={selectedClient.id}
            reload={() => reload()}
            clientData={selectedClient}
          />
        )}
        {menuselection == 3 && (
          <LedgerLogs
            key={selectedClient.id}
            reload={() => reload()}
            clientData={selectedClient}
          />
        )}
        {menuselection == 4 && (
          <RejectionLogs
            key={selectedClient.id}
            reload={() => reload()}
            clientData={selectedClient}
          />
        )}
        {menuselection == 5 && (
          <LoginLogs
            key={selectedClient.id}
            reload={() => reload()}
            clientData={selectedClient}
          />
        )}
        {menuselection == 6 && (
          <Fatak
            key={selectedClient.id}
            reload={() => reload()}
            clientData={selectedClient}
          />
        )}
        {menuselection == 7 && (
          <Locks
            key={selectedClient.id}
            reload={() => reload()}
            clientData={selectedClient}
          />
        )}
      </div>
    </div>
  );
}
