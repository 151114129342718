import React from "react";
import OnlineCard from "./OnlineCard";
import { apiCall } from "../../Backend/Backend";
import UpdateTrade from "../Common/Update";

export default function OnlineUsers({ scrollHieght, onlineUsersData }) {
  // const [onlineUsersData, setOnlineUsersData] = React.useState([]);
  // const [loading, setLoading] = React.useState(true);

  // React.useEffect(() => {
  //   load_online_users();
  // }, []);

  // const load_online_users = async () => {
  //   const response = await apiCall("/load_online_users");
  //   setLoading(false);
  //   setOnlineUsersData(response?.online_users);
  //   setCountUsers(response?.online_users.length);
  // };

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          overflowY: "scroll",
          width: "100%",
          height: scrollHieght,
        }}
      >
        {onlineUsersData &&
          onlineUsersData.map((i, t) => (
            <OnlineCard key={i?.id} type={i?.type} username={i?.username} />
          ))}
      </div>
    </>
  );
}
