import moment from "moment";
import React, { useEffect, useState } from "react";
import Php from "../../Backend/Php";
import SocketContext from "../../SocketContext/SocketContext";
import { NewTheme } from "../Theme/Theme";
import { useNotification } from "../Notification/NotificationContext";
import BidaskCard from "./BidaskCard";

const php = new Php();

function WatchlistCard(props) {
  const item = props.item;
  const { addNotification } = useNotification();
  const socket = React.useContext(SocketContext);
  const [ask, setask] = useState(0);
  const [bid, setbid] = useState(0);
  const [high2, sethigh2] = useState(0);
  const [low2, setlow2] = useState(0);
  const [chng, setchng] = useState(0);
  const [percent, setpercent] = useState(0);
  const [ltp, setltp] = useState(0);
  const [open, setOpen] = useState(0);
  const [close, setClose] = useState(0);
  const [date, setDate] = useState(0);
  const [show, setShow] = useState(false);

  const [bidTick, setBidTick] = useState(false);
  const [askTick, setAskTick] = useState(false);

  useEffect(() => {
    socket.emit("giverate", item.script_id);
    socket.on("trade" + item.script_id, trade);
    socket.on("bidask" + item.script_id, bidask);
    return () => {
      socket.off("trade" + item.script_id, trade);
      socket.off("bidask" + item.script_id, bidask);
    };
  }, []);

  const trade = (msg) => {
    if (msg == null) {
      // alert.error("Script is expire no trading available");
    } else {
      if (bid !== msg.Bid) {
        setbid(msg.Bid);
      }
      if (ask !== msg.Ask) {
        setask(msg.Ask);
      }
      if (high2 !== msg.High) {
        sethigh2(msg.High);
      }
      if (low2 !== msg.Low) {
        setlow2(msg.Low);
      }
      if (msg.LTP - msg.Previous_Close !== chng) {
        setchng(msg.LTP - msg.Previous_Close);
        setpercent(((msg.LTP - msg.Previous_Close) / msg.Previous_Close) * 100);
      }
      if (ltp !== msg.LTP) {
        setltp(msg.LTP);
      }
      if (open !== msg.Open) {
        setOpen(msg.Open);
      }
      if (close !== msg.Previous_Close) {
        setClose(msg.Previous_Close);
      }
      if (date !== msg.Timestamp) {
        setDate(msg.Timestamp);
      }
    }
  };

  const bidask = (msg) => {
    if (bid !== msg.Bid) {
      setbid(msg.Bid);
      setBidTick(true);

      // setTimeout(() => {
      //   setBidTick(false);
      // }, 100);
    }
    if (ask !== msg.Ask) {
      setask(msg.Ask);
      setAskTick(true);

      // setTimeout(() => {
      //   setAskTick(false);
      // }, 100);
    }
    if (date !== msg.Timestamp) {
      setDate(msg.Timestamp);
    }
  };

  const delete_script = (a) => {
    let data = {
      sr: localStorage.getItem("server"),
      jwt: localStorage.getItem("token"),
      m_id: localStorage.getItem("mastersId"),
      watchlist_id: a,
    };

    php.delete_script(data).then((r) => {
      if (r.error == "False") {
        addNotification(r.message, "success");
        props.loadWatchlist();
      } else {
        addNotification(r.message, "error");
      }
    });
  };

  return (
    <div
      key={props.index}
      style={{
        height: "40px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginBottom: 10,
        borderRadius: 10,
        position: "relative",
        borderStyle: "solid",
        borderWidth: 1,
        paddingLeft: 10,
        borderColor: NewTheme.MainColor,
      }}
    >
      <div
        style={{
          flex: 2,
          display: "flex",
          borderRightStyle: "solid",
          height: "100%",
          alignItems: "center",
          borderRightWidth: 1,
          borderRightColor: "lightgray",
        }}
      >
        <div>{props.item.symbol_display}</div>
        <div
          style={{
            fontSize: 10,
            // paddingBottom: 10,
            // paddingLeft: 5,
            fontWeight: "bold",
            color: NewTheme.MainColor,
          }}
        >
          {moment(date).format("DD-MMM")}
          {/* <br /> */}
          {moment(date).format("HH:mm:ss")}
        </div>
      </div>
      <BidaskCard value={bid} />
      <BidaskCard value={ask} />

      <div
        style={{
          flex: 1,
          display: "flex",
          height: "100%",
          alignItems: "center",
          justifyContent: "center",
          borderRightWidth: 1,
          borderRightColor: "lightgray",
          borderRightStyle: "solid",
          color: NewTheme.MainColor,
        }}
      >
        {ltp}
      </div>
      <div
        style={{
          flex: 1,
          display: "flex",
          height: "100%",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          borderRightWidth: 1,
          borderRightColor: "lightgray",
          borderRightStyle: "solid",
          color: chng > 0 ? NewTheme.greencolor : NewTheme.redcolor,
        }}
      >
        <div style={{ fontSize: 14 }}> {parseFloat(chng).toFixed(2)} </div>
        <div>{"(" + parseFloat(percent).toFixed(2) + "%)"}</div>
      </div>
      <div
        style={{
          flex: 1,
          display: "flex",
          height: "100%",
          alignItems: "center",
          justifyContent: "center",
          borderRightWidth: 1,
          borderRightColor: "lightgray",
          borderRightStyle: "solid",
          color: NewTheme.MainColor,
        }}
      >
        {open}
      </div>
      <div
        style={{
          flex: 1,
          display: "flex",
          height: "100%",
          alignItems: "center",
          justifyContent: "center",
          borderRightWidth: 1,
          borderRightColor: "lightgray",
          borderRightStyle: "solid",
          color: NewTheme.MainColor,
        }}
      >
        {high2}
      </div>
      <div
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontWeight: "700",
          borderRightWidth: 1,
          borderRightColor: "lightgray",
          borderRightStyle: "solid",
          color: NewTheme.MainColor,
          height: "100%",
        }}
      >
        {low2}
      </div>
      <div
        style={{
          flex: 1,
          display: "flex",
          height: "100%",
          alignItems: "center",
          justifyContent: "center",
          borderRightWidth: 1,
          borderRightColor: "lightgray",
          borderRightStyle: "solid",
          color: NewTheme.MainColor,
        }}
      >
        {close}
      </div>
      {props.noDelete ? null : (
        <div
          onClick={() => delete_script(item.id)}
          style={{
            flex: 0.5,
            display: "flex",
            borderRightStyle: "solid",
            height: "100%",
            alignItems: "center",
            justifyContent: "center",
            borderRightWidth: 0,
            borderRightColor: "lightgray",
          }}
        >
          <i
            className="pi pi-times"
            style={{
              color: NewTheme.redcolor,
              fontSize: 18,
              width: "50%",
            }}
          ></i>
        </div>
      )}
    </div>
  );
}

export default WatchlistCard;
