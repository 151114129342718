import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import React from "react";

import { useNotification } from "../../Notification/NotificationContext";

import { NewTheme } from "../../Theme/Theme";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import Php from "../../../Backend/Php";
import moment from "moment";
import { COLORSCHEMEA } from "../../../ColorSchema/ColorSchema";

const php = new Php();
const height = window.innerHeight;

export default function ParkingModal(props) {
  const { addNotification } = useNotification();
  const [loading, setLoading] = React.useState(false);
  const [parkingData, setParkingData] = React.useState([]);

  React.useEffect(() => {
    load_parking();
  }, []);

  const load_parking = (e) => {
    if (!loading) {
      setLoading(true);
      let data = {
        sr: localStorage.getItem("server"),
        jwt: localStorage.getItem("token"),
        m_id: localStorage.getItem("mastersId"),
      };

      php.load_parking(data).then((r) => {
        console.log(r);
        setLoading(false);
        if (r.error === "False") {
          setParkingData(r.parking);
        } else {
          addNotification(r.message, "error");
        }
      });
    }
  };

  const footerContent = (
    <div>
      <Button
        label="Close"
        icon="pi pi-times"
        onClick={() => props.setVisible(false)}
        className="p-button-text"
        style={{ color: NewTheme.MainColor }}
      />
    </div>
  );
  return (
    <Dialog
      header={
        <div style={{ color: NewTheme.whiteColor }}>{"Parking Summary "}</div>
      }
      headerStyle={{
        backgroundColor: COLORSCHEMEA.THEMEFONT,
      }}
      visible={props.visible}
      position={props.position}
      style={{ width: "70vw" }}
      breakpoints={{ "960px": "75vw", "641px": "100vw" }}
      onHide={() => props.setVisible(false)}
      footer={footerContent}
      draggable={false}
      resizable={false}
    >
      <DataTable
        removableSort
        stripedRows
        showGridlines
        scrollHeight={height - 200}
        scrollable
        // filters={filters}
        value={parkingData}
        size="small"
        style={{ marginTop: 20 }}
      >
        <Column
          style={{ width: "20%" }}
          sortable
          showFilterMenu={false}
          field="username"
          header="Client"
        ></Column>
        <Column
          style={{ width: "20%" }}
          sortable
          showFilterMenu={false}
          field="symbol"
          header="Symbol"
        ></Column>
        <Column
          style={{ width: "20%" }}
          showFilterMenu={false}
          field="qty"
          header="Qty"
        ></Column>
        <Column
          style={{ width: "20%" }}
          showFilterMenu={false}
          header="Days"
          body={(rowData) => (
            <div>{moment(rowData.date_created).diff(moment(), "days")}</div>
          )}
        ></Column>
        <Column
          style={{ width: "20%" }}
          sortable
          showFilterMenu={false}
          field="date_created"
          header="Date/Time"
          body={(rowData) => (
            <div>
              {moment(rowData.date_created).format("DD-MMM ")}
              <br />
              <span style={{ color: NewTheme.MainColor }}>
                {moment(rowData.date_created).format("HH:mm:ss")}
              </span>
            </div>
          )}
        ></Column>
      </DataTable>
    </Dialog>
  );
}
