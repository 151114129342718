import React from "react";
import { FaUser, FaUserCheck, FaChartPie, FaServer } from "react-icons/fa";
import { MdAccountBalance } from "react-icons/md";
import { RiMoneyRupeeCircleFill } from "react-icons/ri";
import ToggleCard from "./ToggleCard";
import { Button } from "primereact/button";
import moment from "moment";
// import LiveSummaryModal from "./Modal/LiveSummaryModal";
import styled from "styled-components";
// import BalanceModal from "./Modal/BalanceModal";
// import CreditModal from "./Modal/CreditModal";
// import FixedRentModal from "./Modal/FixedRentModal";
import ClientDetailsCard from "../../Users/Newui/ClientDetailsCard";
import { COLORSCHEMEA } from "../../../ColorSchema/ColorSchema";
import { apiCall } from "../../../Backend/Backend";
import { useNotification } from "../../Notification/NotificationContext";
import NumberFormatIn from "../../Common/NumberFormatIn";
import LiveSummaryModal from "../Modal/LiveSummaryModal";

const ResponsiveDiv = styled.div`
  display: flex;
  height: calc(100vh - 135px);
  width: 100%;
  /* padding: 10px; */

  /* Default layout: row direction */
  flex-direction: row;

  /* Media query for screen widths less than 1000px */
  @media (max-width: 1000px) {
    flex-direction: column;
    overflow-y: scroll; /* Makes the container scrollable if content overflows */
    padding: 0px;
    padding-left: 0px;
  }
`;

const StyledDiv = styled.div`
  border-style: solid;
  height: 100%;
  border-width: 1px;
  border-color: ${(props) => props.borderColor || COLORSCHEMEA.THEMEFONT};
  border-radius: 5px;
  flex: 1;

  @media (max-width: 1000px) {
    /* margin: 10px; */
    &:last-child {
      margin-top: 10px;
    }
  }

  @media (min-width: 1001px) {
    &:last-child {
      margin-left: 10px;
    }
  }
`;

export default function NewBasic({ clientData, reload }) {
  const [loading, setLoading] = React.useState(true);
  const [buttonsData, setButtonsData] = React.useState([]);
  const [overviewClientData, setOverviewClientData] = React.useState([]);
  const { addNotification } = useNotification();

  const [visibleLiveSummaryModal, setVisibleLiveSummaryModal] =
    React.useState(false);
  const [visibleBalanceModal, setVisibleBalanceModal] = React.useState(false);
  const [visibleFixedRentModal, setVisibleFixedRentModal] =
    React.useState(false);
  const [visibleCreditModal, setVisibleCreditModal] = React.useState(false);
  const [position, setPosition] = React.useState("top");

  const showBalanceModal = (position) => {
    setPosition("top");
    setVisibleBalanceModal(true);
  };
  const showFixedRentModal = (position) => {
    setPosition("top");
    setVisibleFixedRentModal(true);
  };
  const showCreditModal = (position) => {
    setPosition("top");
    setVisibleCreditModal(true);
  };

  const details =
    overviewClientData?.fatak !== "" ? (
      <>
        {"Notify Over "}
        <NumberFormatIn value={overviewClientData?.fatak} />
        {" Limit"}
      </>
    ) : null;

  React.useEffect(() => {
    load_masters_overview();
  }, []);

  const load_masters_overview = async () => {
    const response = await apiCall("/load_masters_overview");
    setLoading(false);
    setOverviewClientData(response.masters_overview[0]);
    setButtonsData(response.buttons_data);
  };

  return (
    <ResponsiveDiv>
      <StyledDiv className="animate__animated animate__zoomIn animate__faster">
        <div
          style={{
            height: 70,
            display: "flex",
            alignItems: "center",
            paddingLeft: 15,
            justifyContent: "flex-start",
            borderBottomStyle: "solid",
            borderWidth: 0.4,
            paddingLeft: 10,
            paddingRight: 10,
          }}
        >
          <div
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
            }}
          >
            <div style={{ fontSize: 18, fontWeight: "bold" }}>
              Basic Details
            </div>
            <div style={{ fontSize: 12, textTransform: "uppercase" }}>
              {clientData?.username}
            </div>
          </div>
          <div>
            <Button
              size="small"
              label="Live Summary"
              severity="primary"
              outlined
              onClick={() => setVisibleLiveSummaryModal(true)}
            />
          </div>
        </div>
        <ClientDetailsCard
          title={"Server"}
          details={overviewClientData?.server}
          Icon={FaServer}
        />
        <ClientDetailsCard
          title={"Username"}
          details={overviewClientData?.username}
          Icon={FaUser}
        />
        <ClientDetailsCard
          title={"Name"}
          details={overviewClientData?.name}
          subDetails={moment(overviewClientData?.date_created).format(
            "DD-MMM-YY"
          )}
          Icon={FaUserCheck}
        />
        <ClientDetailsCard
          title={"Weekly Profitloss"}
          Icon={FaChartPie}
          details={<NumberFormatIn value={overviewClientData?.weekly_pl} />}
          color={
            parseFloat(overviewClientData?.weekly_pl) >= 0
              ? COLORSCHEMEA.GREENCOLOR
              : COLORSCHEMEA.REDCOLOR
          }
        />

        <ClientDetailsCard
          title={"Deposit"}
          details={
            <NumberFormatIn value={overviewClientData?.deposit_amount} />
          }
          // onClick={() => showCreditModal()}
          Icon={RiMoneyRupeeCircleFill}
        />
        <ClientDetailsCard
          title={"Settlement Balance"}
          Icon={MdAccountBalance}
          color={
            parseFloat(overviewClientData?.balance) >= 0
              ? COLORSCHEMEA.GREENCOLOR
              : COLORSCHEMEA.REDCOLOR
          }
          // onClick={() => showBalanceModal()}
          details={<NumberFormatIn value={overviewClientData?.balance} />}
        />
        {/* <ClientDetailsCard
          title={"Fixed Rent"}
          Icon={MdAccountBalance}
          onClick={() => showFixedRentModal()}
          edit
          details={
            <NumberFormatIn value={overviewClientData?.fixed_rent_amount} />
          }
        /> */}
      </StyledDiv>

      <StyledDiv className="animate__animated animate__zoomIn animate__faster">
        <div
          style={{
            height: 70,
            display: "flex",
            justifyContent: "center",
            paddingLeft: 15,
            flexDirection: "column",
            alignItems: "flex-start",
            borderBottomStyle: "solid",
            borderWidth: 0.4,
          }}
        >
          <div style={{ fontSize: 18, fontWeight: "bold" }}>
            Account Settings
          </div>
          <div style={{ fontSize: 12 }}>Control User Limitations</div>
        </div>

        <ToggleCard
          name={"Status"}
          value={overviewClientData?.status == 1 ? true : false}
          details={overviewClientData?.status == 1 ? "Active" : "Inactive"}
          detailsColor={
            overviewClientData?.status == 1
              ? COLORSCHEMEA.GREENCOLOR
              : COLORSCHEMEA.REDCOLOR
          }
        />

        {buttonsData &&
          buttonsData.map((button, index) => (
            <ToggleCard
              key={index}
              color={button.color}
              value={button.active}
              name={button.text}
              details={button?.active == 1 ? "Active" : "Inactive"}
              detailsColor={
                button?.active == 1
                  ? COLORSCHEMEA.THEMEFONT
                  : COLORSCHEMEA.THEMEGRAY
              }
            />
          ))}
      </StyledDiv>

      {visibleLiveSummaryModal ? (
        <LiveSummaryModal
          key={"livesummary"}
          setVisible={() => setVisibleLiveSummaryModal(false)}
          position={position}
          visible={visibleLiveSummaryModal}
          weekly_pl={overviewClientData?.weekly_pl}
          deposit={overviewClientData?.deposit_amount}
          username={overviewClientData?.username}
        />
      ) : null}
    </ResponsiveDiv>
  );
}
