import React from "react";
import AlertCard from "./AlertCard";
import { apiCall } from "../../Backend/Backend";
import UpdateNotification from "../Common/Notification";

export default function Alerts({ scrollHieght }) {
  const [alertsData, setAlertsData] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    load_notification();
  }, []);

  const load_notification = async () => {
    const response = await apiCall("/load_notification");
    setLoading(false);
    setAlertsData(response?.notification);
  };

  return (
    <>
      <UpdateNotification update={() => load_notification()} />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          overflowY: "scroll",
          width: "100%",
          height: scrollHieght,
        }}
      >
        {alertsData &&
          alertsData.map((i, t) => <AlertCard data={i} key={i.id} />)}
      </div>
    </>
  );
}
