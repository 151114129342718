import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { useEffect, useState } from "react";

import * as ExcelJS from "exceljs";
import jsPDF from "jspdf";
import "jspdf-autotable";
import moment from "moment/moment";
import { FilterMatchMode } from "primereact/api";
import Php from "../../Backend/Php";
import TopbarMost from "../Common/TopbarMost";
import { useNotification } from "../Notification/NotificationContext";
import { NewTheme } from "../Theme/Theme";
import Filter from "./Filter/Filter";
import VolumeModal from "./VolumeModal/VolumeModal";
import { InputText } from "primereact/inputtext";
import NumberFormatIn from "../Common/NumberFormatIn";
import TransactionModal from "./VolumeModal/TransactionModal";
import RentModal from "./VolumeModal/RentModal";
const php = new Php();

const height = window.innerHeight;

export default function Transaction() {
  const [transactionData, setTransactionData] = useState([]);
  const [transactionDataFilter, setTransactionDataFilter] = useState([]);

  const [usersFilter, setUsersFilter] = useState([]);
  const [selectedUser, setSelectedUser] = useState([]);

  const [totalProfitLoss, setProfitLoss] = useState(0);
  const [totalBrokerage, setTotalBrokerage] = useState(0);
  const [totalVolume, setTotalVolume] = useState(0);
  const [totalRent, setTotalRent] = useState(0);

  const [futVolume, setFutVolume] = useState(0);
  const [mcxVolume, setMcxVolume] = useState(0);
  const [mcxLot, setMcxLot] = useState(0);
  const [foVolume, setFoVolume] = useState(0);
  const [forexVolume, setForexVolume] = useState(0);
  const [cryptoVolume, setCryptoVolume] = useState(0);
  const [stocksVolume, setStocksVolume] = useState(0);

  const [positionId, setPositionId] = useState(0);
  const [userId, setUserId] = useState(0);
  const [transactionId, setTransactionId] = useState(0);

  const { addNotification } = useNotification();
  const [loading, setLoading] = React.useState(false);

  const [date, setDate] = useState([new Date(), new Date()]);

  const [filters, setFilters] = useState({
    symbol: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
  });
  const [filterSymbol, setFilterSymbol] = React.useState([]);
  const [filterUser, setFilterUser] = React.useState([]);

  useEffect(() => {
    load_transactions();
  }, []);

  const load_transactions = (e) => {
    if (!loading) {
      setLoading(true);
      let data = {
        sr: localStorage.getItem("server"),
        jwt: localStorage.getItem("token"),
        m_id: localStorage.getItem("mastersId"),
        from_date:
          e == "clear"
            ? moment(new Date()).format("YYYY-MM-DD 23:59:59")
            : moment(date[0]).format("YYYY-MM-DD 00:00:00"),
        to_date:
          e == "clear"
            ? moment(new Date()).format("YYYY-MM-DD 23:59:59")
            : moment(date[1]).format("YYYY-MM-DD 23:59:59"),
        c_id:
          selectedUser?.id == undefined || e == "clear" ? 0 : selectedUser?.id,
      };

      php.load_transactions(data).then((r) => {
        setLoading(false);
        if (r.error === "False") {
          let futVolumeAmt = 0;

          let scripts = r.transactions.map((i, t) => {
            futVolumeAmt += parseFloat(i.volume);
          });

          setTotalBrokerage(parseFloat(r.brokerage).toFixed(2));
          setTotalVolume(parseFloat(r.volume).toFixed(0));
          setProfitLoss(parseFloat(r.profit_loss).toFixed(3));
          setTotalRent(parseFloat(r.rent).toFixed(3));

          setFutVolume(futVolumeAmt);

          setUsersFilter(r.users);
          setTransactionDataFilter(r.transactions);
          setTransactionData(r.transactions);
        } else {
          addNotification(r.message, "error");
        }
      });
    }
  };

  const cols = [
    { dataKey: "username", title: "Username" },
    { dataKey: "symbol", title: "Symbol" },
    { dataKey: "qty", title: "Qty" },
    { dataKey: "buy_sell", title: "B/S" },
    { dataKey: "rate", title: "Rate" },
    { dataKey: "profit_loss", title: "P/L" },
    { dataKey: "brokerage_amt", title: "Brokerage" },
    { dataKey: "volume", title: "Volume" },
    { dataKey: "deposit", title: "Deposit" },
    { dataKey: "trade_msg", title: "Details" },
    { dataKey: "date_created", title: "Date" },
  ];

  const exportPdf = () => {
    // Your column and data definitions (replace these with your actual data)
    const doc = new jsPDF({
      orientation: "landscape",
    });
    const additionalCols = ["Transactions Report", "", ""];

    var additionalData = [
      [1, "Profit/Loss", parseFloat(totalProfitLoss)],
      [2, "Brokerage", parseFloat(totalBrokerage)],
      [3, "Volume", parseFloat(totalVolume)],
      [4, "Rent", parseFloat(totalRent)],
    ];

    transactionData.forEach((row) => {
      // Convert the value to a number for loose equality check

      const buySellValue = Number(row.buy_sell);

      row.buy_sell = buySellValue == 0 ? "Buy" : "Sell";

      const symbolValue =
        row.symbol + "\n" + moment(row.expiry_date).format("DD-MMM-YY");

      row.symbol = symbolValue;
    });

    doc.autoTable({
      head: [additionalCols],
      body: additionalData,
    });

    const headerStyles = {
      fillColor: "#4a6ba1",
      textColor: "#ffffff",
    };

    // Add content to the PDF using autoTable with modified data and custom header styles
    const tableStartY = 80; // Adjust the startY value based on the header size and layout
    doc.autoTable(cols, transactionData, { startY: tableStartY, headerStyles });

    var pdfName =
      selectedUser?.username != undefined
        ? selectedUser?.username + " - Transaction"
        : localStorage.getItem("server")?.toUpperCase() + " - Transaction";
    // Save or display the PDF

    doc.save(pdfName + ".pdf");
  };

  const [visible, setVisible] = useState(false);
  const [visibleTransactionModal, setVisibleTransactionModal] = useState(false);
  const [visibleRentModal, setVisibleRentModal] = useState(false);
  const [position, setPosition] = useState("top");

  const show = (position) => {
    setVisible(true);
  };

  const showTransactionModal = (position_id) => {
    setPositionId(position_id);
    setVisibleTransactionModal(true);
  };

  const showRentModal = (transaction_id, user_id) => {
    setTransactionId(transaction_id);
    setUserId(user_id);
    setVisibleRentModal(true);
  };

  const exportExcel = () => {
    // Create a new Excel workbook
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("data");

    // Specify the actual columns you want to export
    const colsToExport = [
      "username",
      "symbol",
      "qty",
      "buy_sell",
      "position_rate",
      "rate",
      "profit_loss",
      "brokerage",
      "brokerage_amt",
      "volume",
      "deposit",
      "trade_msg",
      "date_created",
    ];

    // Specify the display names for the headers
    const headerDisplayNames = [
      "Username",
      "Symbol",
      "Quantity",
      "Buy/Sell",
      "Position Rate",
      "Rate",
      "Profit/Loss",
      "Brokerage",
      "Brokerage Amt",
      "Volume",
      "Deposit",
      "Trade Message",
      "Date Created",
    ];

    const headerRow = worksheet
      .addRow([
        selectedUser?.username != undefined
          ? selectedUser?.username + " - Transaction"
          : localStorage.getItem("server")?.toUpperCase() + " - Transaction",
      ])
      .commit();
    worksheet.mergeCells(`A${worksheet.rowCount}:L${worksheet.rowCount}`);
    const mergedRange = worksheet.getCell(
      `A${worksheet.rowCount}:L${worksheet.rowCount}`
    );
    mergedRange.alignment = { horizontal: "center" };
    mergedRange.font = { bold: true, size: 16 };

    worksheet
      .addRow([1, "Profit/Loss", parseFloat(totalProfitLoss)])
      .eachCell((cell, colNumber) => {
        // Apply color to font based on the "totalProfitLoss" condition
        if (colNumber === 3 && !isNaN(totalProfitLoss)) {
          const color =
            totalProfitLoss > 0
              ? NewTheme.greencolorExcel
              : NewTheme.redcolorExcel; // Green for positive, Red for negative

          cell.font = {
            color: { argb: color },
          };

          // Convert totalProfitLoss to number and set number format
          cell.value = Number(totalProfitLoss);
          cell.numFmt = "#,##0.00"; // Adjust the number format as needed
        }
      });
    worksheet.addRow([2, "Brokerage", parseFloat(totalBrokerage)]);
    worksheet.addRow([3, "Volume", parseFloat(totalVolume)]);
    worksheet.addRow([4, "Rent", parseFloat(totalRent)]);

    // Add empty rows between summary and data
    for (let i = 0; i < 2; i++) {
      worksheet.addRow([]);
    }

    // Add column headers to the worksheet with display names
    const headers = worksheet.addRow(headerDisplayNames);

    headers.eachCell((cell) => {
      cell.font = {
        bold: true,
      };
    });

    // Extract only the desired columns from transactionData
    const filteredData = transactionData.map((item) => {
      return colsToExport.reduce((acc, col) => {
        if (col === "buy_sell") {
          acc[col] = item[col] == 0 ? "Buy" : "Sell";
        } else {
          acc[col] = item[col];
        }
        return acc;
      }, {});
    });

    // Add the filtered data to the worksheet
    filteredData.forEach((data) => {
      worksheet.addRow(Object.values(data));
    });

    // Set column width to 16 for all columns
    worksheet.columns.forEach((column) => {
      column.width = 16;
    });

    const symbolIndex = colsToExport.indexOf("symbol");
    const colIndex = colsToExport.indexOf("profit_loss");
    const qtyIndex = colsToExport.indexOf("qty");
    const positionRateIndex = colsToExport.indexOf("position_rate");
    const brokerageIndex = colsToExport.indexOf("brokerage");
    const brokerageAmtIndex = colsToExport.indexOf("brokerage_amt");
    const volumeIndex = colsToExport.indexOf("volume");
    const depositIndex = colsToExport.indexOf("deposit");
    const rateIndex = colsToExport.indexOf("rate");

    worksheet.eachRow({ includeEmpty: false }, (row, rowNumber) => {
      const cellValue = row.getCell(colIndex + 1).value;
      const qtyValue = row.getCell(qtyIndex + 1).value;
      const positionRateValue = row.getCell(positionRateIndex + 1).value;
      const brokerageValue = row.getCell(brokerageIndex + 1).value;
      const brokerageAmtValue = row.getCell(brokerageAmtIndex + 1).value;
      const volumeValue = row.getCell(volumeIndex + 1).value;
      const depositValue = row.getCell(depositIndex + 1).value;
      const rateValue = row.getCell(rateIndex + 1).value;
      const symbolValue = row.getCell(symbolIndex + 1).value;

      // Format as numbers with two decimal places
      const formatAsNumber = (cell, value) => {
        if (value !== null && !isNaN(value) && rowNumber > 4) {
          cell.value = rowNumber > 8 ? Number(value) : value;
          cell.numFmt = rowNumber > 8 ? "#,##0.00" : undefined; // Adjust the number format as needed
        }
      };

      formatAsNumber(row.getCell(colIndex + 1), cellValue);
      formatAsNumber(row.getCell(qtyIndex + 1), qtyValue);
      formatAsNumber(row.getCell(positionRateIndex + 1), positionRateValue);
      formatAsNumber(row.getCell(brokerageIndex + 1), brokerageValue);
      formatAsNumber(row.getCell(brokerageAmtIndex + 1), brokerageAmtValue);
      formatAsNumber(row.getCell(volumeIndex + 1), volumeValue);
      formatAsNumber(row.getCell(depositIndex + 1), depositValue);
      formatAsNumber(row.getCell(rateIndex + 1), rateValue);

      if (cellValue !== null && !isNaN(cellValue) && rowNumber > 4) {
        const color =
          cellValue > 0 ? NewTheme.greencolorExcel : NewTheme.redcolorExcel; // Green for positive, Red for negative

        row.getCell(colIndex + 1).font = {
          color: { argb: color },
        };
      }
      if (depositValue !== null && !isNaN(depositValue) && rowNumber > 4) {
        const depositColor =
          depositValue > 0 ? NewTheme.greencolorExcel : NewTheme.redcolorExcel; // Green for positive, Red for negative

        row.getCell(depositIndex + 1).font = {
          color: { argb: depositColor },
        };
      }
    });

    // Add empty rows between data and footer
    for (let i = 0; i < 2; i++) {
      worksheet.addRow([]);
    }

    var fileName =
      selectedUser?.username != undefined
        ? selectedUser?.username + " - Transaction"
        : localStorage.getItem("server")?.toUpperCase() + " - Transaction";

    // Create Excel file and trigger download
    workbook.xlsx.writeBuffer().then((buffer) => {
      saveAsExcelFile(buffer, fileName);
    });
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((module) => {
      if (module && module.default) {
        let EXCEL_TYPE =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        let EXCEL_EXTENSION = ".xlsx";
        const data = new Blob([buffer], {
          type: EXCEL_TYPE,
        });

        module.default.saveAs(
          data,
          fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
        );
      }
    });
  };

  const [filtersGlobal, setFiltersGlobal] = React.useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

  const value = filtersGlobal["global"] ? filtersGlobal["global"].value : "";
  const onGlobalFilterChange = (event) => {
    const value = event.target.value;
    let _filters = { ...filtersGlobal };

    _filters["global"].value = value;

    setFilters(_filters);
  };

  const overviewBar = (
    <div
      style={{
        height: "100%",
        width: "100%",
        display: "flex",
        justifyContent: "flex-end",
        paddingRight: 20,
        fontWeight: "bold",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-evenly",
          alignItems: "center",
        }}
      >
        <div
          style={{
            // height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginLeft: 10,
            flexDirection: "column",
            minWidth: 160,
            backgroundColor: "#ffff",
            padding: "0 10px",
            borderRadius: 4,
          }}
          // onClick={() => show()}
        >
          <div style={{ fontSize: 12, color: NewTheme.blackColor }}>Rent</div>
          <div
            style={{
              fontSize: 16,
              marginTop: 3,
              backgroundColor: "#ffff",
              padding: "0 10px",
              borderRadius: 5,
              color: totalRent >= 0 ? NewTheme.greencolor : NewTheme.redcolor,
            }}
          >
            <NumberFormatIn value={parseFloat(totalRent).toFixed(2)} />
          </div>
        </div>
        <div
          style={{
            // height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginLeft: 10,
            flexDirection: "column",
            minWidth: 200,
            backgroundColor: "#ffff",
            padding: "0 10px",
            borderRadius: 4,
          }}
        >
          <div
            style={{
              fontSize: 12,
              color: NewTheme.blackColor,
            }}
          >
            Profitloss
          </div>
          <div
            style={{
              fontSize: 16,
              marginTop: 3,
              color:
                totalProfitLoss >= 0 ? NewTheme.greencolor : NewTheme.redcolor,
            }}
          >
            <NumberFormatIn value={parseFloat(totalProfitLoss).toFixed(2)} />
          </div>
        </div>
        <div
          style={{
            // height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginLeft: 10,
            flexDirection: "column",
            minWidth: 160,
            backgroundColor: "#ffff",
            padding: "0 10px",
            borderRadius: 4,
          }}
        >
          <div style={{ fontSize: 12, color: NewTheme.blackColor }}>
            Brokerage
          </div>
          <div
            style={{
              fontSize: 16,
              color: NewTheme.greencolor,
              marginTop: 3,
              backgroundColor: "#ffff",
              padding: "0 10px",
              borderRadius: 5,
            }}
          >
            <NumberFormatIn value={totalBrokerage} />
          </div>
        </div>
        <div
          style={{
            // height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginLeft: 10,
            flexDirection: "column",
            minWidth: 160,
            backgroundColor: "#ffff",
            padding: "0 10px",
            borderRadius: 4,
          }}
          // onClick={() => show()}
        >
          <div style={{ fontSize: 12, color: NewTheme.blackColor }}>Volume</div>
          <div
            style={{
              fontSize: 16,
              marginTop: 3,
              backgroundColor: "#ffff",
              padding: "0 10px",
              borderRadius: 5,
              color: "black",
            }}
          >
            <NumberFormatIn value={totalVolume} />
          </div>
        </div>
      </div>
    </div>
  );

  const filterbar = (
    <div
      style={{
        height: "100%",
        width: "100%",
        display: "flex",
        justifyContent: "flex-end",
        paddingRight: 20,
        fontWeight: "bold",
      }}
    >
      <div
        style={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginLeft: 20,
        }}
      >
        <InputText
          style={{ width: 140 }}
          type="search"
          id="input"
          value={value || ""}
          onChange={(e) => onGlobalFilterChange(e)}
          placeholder="Search"
        />
      </div>
      <Filter
        selectedUser={selectedUser}
        usersFilter={usersFilter}
        setSelectedUser={(e) => setSelectedUser(e)}
        date={date}
        setDate={(e) => setDate(e)}
        clearFilter={() => load_transactions("clear")}
        load_transactions={load_transactions}
      />
      <div
        style={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginLeft: 10,
        }}
      >
        <Button
          type="button"
          label="PDF"
          style={{
            height: 25,
            paddingLeft: 10,
            paddingRight: 10,
            fontSize: 12,
            color: NewTheme.orangecolor,
            backgroundColor: "#fff",
          }}
          onClick={exportPdf}
          data-pr-tooltip="PDF"
        />
      </div>
      <div
        style={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginLeft: 10,
        }}
      >
        <Button
          type="button"
          label="XLS"
          style={{
            height: 25,
            paddingLeft: 10,
            paddingRight: 10,
            fontSize: 12,
            color: NewTheme.greencolor,
            backgroundColor: "#fff",
          }}
          onClick={exportExcel}
          data-pr-tooltip="EXCEL"
        />
      </div>
    </div>
  );

  return (
    <div
      className="card"
      style={{
        // background: "black",
        // padding: "0.5rem",
        paddingTop: 0,
        // borderRadius: "10px",
        // marginBottom: "1rem",
        overflow: "hidden",
      }}
    >
      <TopbarMost
        background={"#ffffff"}
        name="Transactions"
        cmp={overviewBar}
      />
      <TopbarMost
        background={"#ffffff"}
        noSidebar
        height={35}
        name="Transactions"
        cmp={filterbar}
      />

      <div id={"stick"} style={{ position: "relative" }}>
        <DataTable
          removableSort
          stripedRows
          showGridlines
          scrollable
          scrollHeight={height - 160}
          size="small"
          // filterDisplay="row"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          value={transactionData}
          style={{ fontWeight: "bold", fontSize: 14 }}
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
          globalFilterFields={[
            "username",
            "symbol",
            "trade_msg",
            "date_created",
          ]}
          paginator
          rows={15}
          loading={loading}
          filters={filters}
          onFilter={(e) => setFilters(e.filters)}
          rowsPerPageOptions={[10, 15, 30, 50, 100]}
        >
          <Column
            field="username"
            showFilterMenu={false}
            header="Clients"
            sortable
            filter
          />
          <Column
            field="symbol"
            showFilterMenu={false}
            header="Symbols"
            sortable
            filter
            body={(rowData) => (
              <>
                <div
                  style={{
                    color: NewTheme.MainColor,
                  }}
                >
                  {rowData.symbol}
                </div>
              </>
            )}
          />
          <Column
            filter
            field="qty"
            showFilterMenu={false}
            header="Qty"
            body={(rowData) => (
              <div
                style={{
                  color:
                    rowData.buy_sell == 0
                      ? NewTheme.greencolor
                      : NewTheme.redcolor,
                }}
              >
                {rowData.qty}
              </div>
            )}
          />
          <Column
            filter
            field="buy_sell"
            showFilterMenu={false}
            header="B/S"
            body={(rowData) => (
              <div
                style={{
                  color:
                    rowData.buy_sell == 0
                      ? NewTheme.greencolor
                      : NewTheme.redcolor,
                }}
              >
                {rowData.buy_sell == 0 ? "Buy" : "Sell"}
              </div>
            )}
          />
          <Column
            filter
            field="position_rate"
            showFilterMenu={false}
            header="Position Rate"
          />
          <Column field="rate" filter showFilterMenu={false} header="Rate" />
          <Column
            filter
            field="profit_loss"
            showFilterMenu={false}
            header="P/L"
            sortable
            body={(rowData) => (
              <div
                style={{
                  color:
                    rowData.profit_loss >= 0
                      ? NewTheme.greencolor
                      : NewTheme.redcolor,
                }}
              >
                <NumberFormatIn
                  value={parseFloat(rowData.profit_loss).toFixed(2)}
                />
              </div>
            )}
          />

          <Column
            field="brokerage_amt"
            sortable
            body={(rowData) => (
              <>
                <div>
                  <NumberFormatIn value={rowData.brokerage_amt} />
                </div>
              </>
            )}
            showFilterMenu={false}
            header="Brokerage"
          />

          <Column field="volume" showFilterMenu={false} header="Volume " />

          <Column
            field="deposit"
            filter
            sortable
            showFilterMenu={false}
            header="Deposit "
            body={(rowData) => (
              <span>
                <NumberFormatIn value={rowData.deposit} />
              </span>
            )}
          />

          <Column
            field="trade_msg"
            filter
            sortable
            showFilterMenu={false}
            header="Details"
          />
          <Column
            filter
            field="date_created"
            style={{ wordWrap: "break-word" }}
            showFilterMenu={false}
            header="Date/Time"
            body={(rowData) => (
              <div>
                {moment(rowData.date_created).format("DD-MMM HH:mm:ss")}
              </div>
            )}
            sortable
          />
          <Column
            field="date_created"
            style={{ wordWrap: "break-word" }}
            showFilterMenu={false}
            header=""
            body={(rowData) => (
              <div style={{ display: "flex", gap: 5 }}>
                {rowData.trade_rent == 0 ? (
                  <Button
                    type="button"
                    icon="pi pi-info"
                    severity="info"
                    style={{ height: 25, width: 25 }}
                    onClick={() => showTransactionModal(rowData.position_id)}
                    data-pr-tooltip="PDF"
                  />
                ) : (
                  <Button
                    type="button"
                    icon="pi pi-info"
                    severity="info"
                    style={{ height: 25, width: 25 }}
                    onClick={() => showRentModal(rowData.id, rowData.users_id)}
                    data-pr-tooltip="PDF"
                  />
                )}
              </div>
            )}
          />
        </DataTable>
      </div>

      {visible ? (
        <VolumeModal
          key={new Date()}
          setVisible={() => setVisible(false)}
          position={position}
          visible={visible}
          futVolume={futVolume}
          mcxVolume={mcxVolume}
          foVolume={foVolume}
          forexVolume={forexVolume}
          cryptoVolume={cryptoVolume}
          stocksVolume={stocksVolume}
          mcxLot={mcxLot}
        />
      ) : null}

      {visibleTransactionModal && (
        <TransactionModal
          key={new Date()}
          setVisible={() => setVisibleTransactionModal(false)}
          position={position}
          visible={visibleTransactionModal}
          positionId={positionId}
          reload={() => load_transactions()}
        />
      )}

      {visibleRentModal && (
        <RentModal
          key={new Date()}
          setVisible={() => setVisibleRentModal(false)}
          position={position}
          visible={visibleRentModal}
          positionId={positionId}
          reload={() => load_transactions()}
        />
      )}
    </div>
  );
}
