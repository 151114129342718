import React from "react";
import styled, { css } from "styled-components";

import { Card } from "primereact/card";
import { CreateClientButton } from "../../Users/Overview/Styled";
import Php from "../../../Backend/Php";
import { NewTheme } from "../../Theme/Theme";
import { useNotification } from "../../Notification/NotificationContext";
import { COLORSCHEMEA } from "../../../ColorSchema/ColorSchema";
import { Button } from "primereact/button";
import { FaKey, FaLock } from "react-icons/fa";
import ClientDetailsCard from "../../Users/Newui/ClientDetailsCard";
import { apiCall } from "../../../Backend/Backend";

const php = new Php();

const ResponsiveDiv = styled.div`
  display: flex;
  height: calc(100vh - 135px);
  width: 100%;
  /* padding: 10px; */

  /* Default layout: row direction */
  flex-direction: row;

  /* Media query for screen widths less than 1000px */
  @media (max-width: 1000px) {
    flex-direction: column;
    overflow-y: scroll; /* Makes the container scrollable if content overflows */
    padding: 0px;
    padding-left: 0px;
  }
`;

const StyledDiv = styled.div`
  border-style: solid;
  height: 100%;
  border-width: 1px;
  border-color: ${(props) => props.borderColor || COLORSCHEMEA.THEMEFONT};
  border-radius: 5px;
  flex: 1;

  @media (max-width: 1000px) {
    /* margin: 10px; */
    &:last-child {
      margin-top: 10px;
    }
  }

  @media (min-width: 1001px) {
    &:last-child {
      margin-left: 10px;
    }
  }
`;

export default function Edit() {
  const [loading, setLoading] = React.useState(false);
  const { addNotification } = useNotification();
  const [formData, setFormData] = React.useState({
    password: "",
    confirm_password: "",
  });

  const logoutFunc = () => {
    localStorage.clear();
    window.location = "/";
  };

  const formConfig = [
    {
      key: "password",
      placeholder: "******",
      type: "text",
      Icon: FaKey,
    },
    {
      key: "confirm_password",
      placeholder: "******",
      type: "text",
      Icon: FaLock,
    },
  ];

  const change_password = async () => {
    // Define validation errors and messages
    const validations = [
      {
        condition: !formData?.password,
        message: "Validation failed: Password field is empty.",
      },
      {
        condition: formData?.password && formData.password.length < 3,
        message:
          "Validation failed: Password must be at least 3 characters long.",
      },
      {
        condition: !formData?.confirm_password,
        message: "Validation failed: Confirm Password field is empty.",
      },
      {
        condition: formData?.password !== formData?.confirm_password,
        message:
          "Validation failed: Password and Confirm Password do not match.",
      },
    ];

    // Check for the first validation error
    const error = validations.find((validation) => validation.condition);
    if (error) {
      addNotification(error.message, "error");
      return;
    }

    // Proceed with the request if all validations pass
    if (!loading) {
      setLoading(true); // Set loading to true while the request is processing
      let send_data = {
        password: formData.password,
        confirm_password: formData.confirm_password,
      };
      console.log(send_data, "send");
      try {
        const response = await apiCall("/edit_master_password", send_data);
        addNotification(
          response.message,
          response.error === "False" ? "success" : "error"
        );
      } catch (error) {
        console.error("Error:", error);
        addNotification(
          "An error occurred while changing the password.",
          "error"
        );
      } finally {
        setFormData({
          password: "",
          confirm_password: "",
        });
        logoutFunc();
        setLoading(false);
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  return (
    <ResponsiveDiv>
      <StyledDiv className="animate__animated animate__zoomIn animate__faster">
        <div
          style={{
            height: 70,
            display: "flex",
            alignItems: "center",
            paddingLeft: 15,
            justifyContent: "flex-start",
            borderBottomStyle: "solid",
            borderWidth: 0.4,
            paddingLeft: 10,
            paddingRight: 10,
          }}
        >
          <div
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
            }}
          >
            <div style={{ fontSize: 18, fontWeight: "bold" }}>
              Edit Password
            </div>
            <div style={{ fontSize: 12, textTransform: "uppercase" }}>
              {localStorage.getItem("mastersUsername")?.toUpperCase()}
            </div>
          </div>
          <div>
            <Button
              type="button"
              size="small"
              label="Update"
              severity="primary"
              onClick={change_password}
            />
          </div>
        </div>

        {Object.keys(formData).map((key) => {
          if (key === "fixed_rent") return null; // Skip "fixed_rent"

          const config = formConfig.find((item) => item.key === key);
          return (
            <ClientDetailsCard
              Icon={config?.Icon}
              input
              type={config?.type || "text"} // Default to 'text' if no type is specified
              name={key}
              title={config?.title || key}
              key={key}
              value={formData[key]}
              flex="1"
              onChange={handleChange}
              placeholder={config?.placeholder || ""}
            />
          );
        })}
      </StyledDiv>
    </ResponsiveDiv>
  );
}
