import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import React from "react";
import { NewTheme } from "../../Theme/Theme";

export default function Filter(props) {
  return (
    <>
      <div
        style={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginLeft: 10,
        }}
      >
        <Dropdown
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minWidth: 160,
            height: 25,
          }}
          showClear
          onChange={(e) => {
            if (e.value == undefined) {
              props.setSelectedUser(e.value);
            } else {
              props.setSelectedUser(e.value);
            }
          }}
          value={props.selectedUser}
          options={props.usersFilter}
          optionLabel="username"
          placeholder=" Username"
          filter
        />
      </div>

      <div
        style={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginLeft: 10,
        }}
      >
        <Calendar
          style={{ minWidth: 220, height: 25 }}
          selectionMode="range"
          id="calendar-12h"
          dateFormat="dd/mm/yy"
          value={props.date}
          onChange={(e) => props.setDate(e.value)}
        />
      </div>

      <div
        style={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginLeft: 10,
        }}
      >
        <Button
          type="button"
          // icon="pi pi-filter"
          label="SUBMIT"
          style={{
            height: 25,
            fontSize: 10,
            padding: 10,
            color: NewTheme.MainColor,
            backgroundColor: "#fff",
          }}
          onClick={() => props.load_transactions()}
          data-pr-tooltip="XLS"
        />
      </div>
      <div
        style={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginLeft: 10,
        }}
      >
        <Button
          type="button"
          // icon="pi pi-times"
          label="CLEAR"
          // severity="danger"
          style={{
            height: 25,
            // width: 30,
            fontSize: 12,
            padding: 10,
            color: NewTheme.redcolor,
            backgroundColor: "#fff",
          }}
          onClick={() => {
            props.clearFilter();
          }}
          data-pr-tooltip="XLS"
        />
      </div>
    </>
  );
}
