import { useWindowSize } from "@react-hook/window-size";
import React from "react";
import { TabView, TabPanel } from "primereact/tabview";
import { COLORSCHEMEA } from "../../ColorSchema/ColorSchema";
import { CiSettings } from "react-icons/ci";
import StatCard from "./StatCard";
import Alerts from "./Alerts";
import OnlineUsers from "./OnlineUsers";
import RightViewTopComp from "./HomeComponents/RightViewTopComp";
import UpdateTrade from "../Common/Update";
import { apiCall } from "../../Backend/Backend";
import UpdateOnlineStatus from "../Common/UpdateOnlineStatus";

export default function RightView() {
  const [width, height] = useWindowSize();
  const [countUsers, setCountUsers] = React.useState(0);
  const scrollHieght = height - 320;
  const header1 = (a) => {
    return (
      <div
        style={{
          fontWeight: "bold",
          background: COLORSCHEMEA.THEMEFONT,
          color: "white",
          height: 50,
          display: "flex",
          alignItems: "center",
          width: "100%",
        }}
      >
        <div style={{ flex: 1 }} onClick={a.onClick}>
          Alerts
        </div>
        <div style={{ width: 30, alignSelf: "center" }}>
          <CiSettings size={20} />
        </div>
      </div>
    );
  };
  const header2 = (a) => {
    return (
      <div
        onClick={a.onClick}
        style={{
          fontWeight: "bold",
          background: COLORSCHEMEA.THEMEFONT,
          color: "white",
          height: 50,
          display: "flex",
          alignItems: "center",
          width: "100%",
        }}
      >
        {"Online Users (" + countUsers + ")"}
      </div>
    );
  };

  const [onlineUsersData, setOnlineUsersData] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    load_online_users();
  }, []);

  const load_online_users = async () => {
    const response = await apiCall("/load_online_users");
    setLoading(false);
    setOnlineUsersData(response?.online_users);
    setCountUsers(response?.online_users.length);
  };

  return (
    <>
      <UpdateOnlineStatus update={() => load_online_users()} />
      <div style={{ width: 300, display: "flex", flexDirection: "column" }}>
        <RightViewTopComp />
        <div style={{ flex: 1 }}>
          <TabView style={{ height: 55, background: COLORSCHEMEA.THEMEFONT }}>
            <TabPanel
              headerTemplate={header1}
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                height: "100%",
                padding: 0,
              }}
            >
              <Alerts scrollHieght={scrollHieght} />
            </TabPanel>
            <TabPanel
              headerTemplate={header2}
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <OnlineUsers
                onlineUsersData={onlineUsersData}
                scrollHieght={scrollHieght}
              />
            </TabPanel>
          </TabView>
        </div>
      </div>
    </>
  );
}
